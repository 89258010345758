<template>
  <div class="manufacturer">
    <div class="content">
      <el-button v-if="!isEdit" class="editBtn" size="small" icon="el-icon-edit" @click="isEdit = !isEdit">修改信息</el-button>
      <span class="title">{{isEdit?'编辑':''}}厂家信息</span>
      <el-form :model="form" :rules="rules" class="info" :class="{isEdit:!isEdit}" ref="form">
        <el-form-item prop="name" class="item">
          <label>公司名称：</label>
          <el-input v-if="isEdit" v-model="form.name" clearable/>
          <span v-else>{{form.name}}</span>
        </el-form-item>
        <el-form-item prop="phone" class="item">
          <label>电话：</label>
          <el-input v-if="isEdit" v-model="form.phone" clearable/>
          <span v-else>{{form.phone}}</span>
        </el-form-item>
        <el-form-item prop="email" class="item">
          <label>Email：</label>
          <el-input v-if="isEdit" v-model="form.email" clearable/>
          <span v-else>{{form.email}}</span>
        </el-form-item>
        <el-form-item prop="website" class="item">
          <label>网址：</label>
          <el-input v-if="isEdit" v-model="form.website" clearable/>
          <span v-else>{{form.website}}</span>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer" v-if="isEdit">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="checkFormSubmit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { getfacturerDetail,editFacturer } from '@/api/soc.js'
export default {
  name: "manufacturer",
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value.startsWith('http')) {
        callback(new Error('请输入正确的网址'));
      } else {
        callback();
      }
    };
    return {
      isEdit:false,
      form:{
        name:'广州里工实业有限公司',
        phone:'13794247777',
        email:'13794247777@qq.com',
        website:'广州里工实业有'
      },
      formSource:{},
      rules:{
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        phone:[
          { required: true, message: '请输入电话', trigger: 'blur' },
          { pattern:new RegExp("^(?:(?:\\+|00)86)?1[3-9]\\d{9}$|^0\\d{2,3}-?\\d{7,8}$"), message: "请输入合法手机号/电话号", trigger: ['blur', 'change'] }
        ],
         email:[
          { required: true, message: '请输入Email', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
         website:[
          { required: true, message: '请输入网址', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
      }
    };
  },
  watch:{
    isEdit(){
      this.$refs.form.clearValidate()
    }
  },
  activated(){
    this.getDetails()
  },
  deactivated(){
    this.isEdit = false
    Object.assign(this.form,this.$options.data().form)
  },
  methods:{
    handleClose(){
      this.isEdit = !this.isEdit
      // Object.assign(this.form,this.$options.data().form)
      this.form = JSON.parse(JSON.stringify(this.formSource))
      console.log('this.form=============',this.form)
    },
    //获取企业简要
    getDetails(){
      getfacturerDetail().then(res=>{
        this.form = res.data
        this.formSource = JSON.parse(JSON.stringify(res.data))
      })
    },
    // 提交校验
    checkFormSubmit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.onSave()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //修改信息
    onSave(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          editFacturer(this.form).then(res=>{
            if(res.code==200){
              this.$message.success('修改成功')
              this.isEdit = false
            }else{
              this.$message.error(res.msg || '修改失败')
            }
          })
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/element-reset.scss';
.manufacturer{
  padding:10px 20px;
  position: relative;
  height: calc(100vh - 180px);
  text-align: left;
  color: #E8E8E8;
  .content{
    background: #262626;
    border-radius: 8px;
    position: relative;
    padding: 23px 39px;
    ::v-deep .el-input{
      font-size: 16px;
    }
    .editBtn{
      position: absolute;
      top:10px;
      right: 20px;
    }
    .title{
      font-size: 20px;
    }
    .info{
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      label,span{
        font-size: 18px;
      }
    }
    .isEdit{
      .item{
        display: flex;
      }
    }
  }
  .footer{
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    line-height: 60px;
    background: #262626;
    margin: 0px 2px;
    padding-right: 32px;
  }
}
</style>
